.first .first-content {
  padding-bottom: 2.666667rem;
}

.first .bottom-bar {
  box-shadow: 0 -0.053333rem 0.133333rem 0 rgba(40,50,102,0.1);
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding-top: 0.266667rem;
  font-size: 0.24rem;
}

.first .bottom-bar .bar-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.266667rem;
}

.first .bottom-bar .bar-item .barActive {
  color: #1890ff;
}

.first .bottom-bar .bar-item .bar-icon {
  width: 1.6rem;
  text-align: center;
  padding: 0 0.4rem;
}

.first .bottom-bar .bar-item .bar-icon img {
  height: 0.64rem;
  width: 100%;
  object-fit: contain;
}

.first .bottom-bar .bar-item .bar-text {
  text-align: center;
}

.first .bottom-bar .active {
  color: #1890ff;
}